import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Content, { HTMLContent } from "../components/Content"
import "./contact.css"
import ContactButtons from "../components/ContactButtons/ContactButtons"

export const ContactPageTemplate = ({
  header,
  paragraph,
  fb_link,
  telephone,
  email,
  html,
  insta_link,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div className="contact-page-container">
      <h2 className="page-header">{header}</h2>
      <p className="contact-paragraph">{paragraph}</p>
      <ContactButtons
        className="contact-buttons"
        fb_link={fb_link}
        fb_btn="facebook"
        insta_link={insta_link}
        insta_btn="instagram"
        telephone={telephone}
        email={email}
        main_bg="var(--pink-color)"
        action_bg="var(--blue-color)"
        border="none"
      />
      <PageContent content={html} className="about-content" />
    </div>
  )
}

const ContactPage = ({ data, location }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <Layout location={location} seoTitle="Kontakt">
      <ContactPageTemplate
        {...frontmatter}
        html={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header
        paragraph
        fb_link
        email
        telephone
        insta_link
      }
      html
    }
  }
`
